import { ActionCategory } from '@core/referential/action-category.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { redirect, createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { actionParamsListByCategoryQuery } from '@/queries/action-params-list-by-category.query'

export type Search = z.infer<typeof SearchSchema>

export const SearchSchema = z.object({
  tab: z.nativeEnum(ActionCategory).default(ActionCategory.SAMPLE),
})

export const Route = createFileRoute('/_dashboard/site/$id/actions-assignation/')({
  validateSearch: SearchSchema,
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_ACTION_ASSIGNATION)) {
      throw redirect({
        to: '/403',
      })
    }

    if (!isUseCase(UseCase.LEGIONELLA)) {
      throw redirect({ to: '/' })
    }
  },
  loaderDeps: ({ search: { tab } }) => ({ tab }),
  loader({ params, context: { queryClient }, deps }) {
    void queryClient.ensureQueryData(actionParamsListByCategoryQuery(params.id, deps.tab))
  },
})
