import { ActionCategory } from '@core/referential/action-category.enum'
import { ActionType } from '@core/referential/action-type.enum'
import { ConditionType } from '@core/referential/condition-type.enum'
import { Frequency } from '@core/referential/frequency.enum'
import { WaterType } from '@core/referential/water-type.enum'
import { z } from 'zod'

import { conditionSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'

export const GetActionParamPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  equipmentId: z.string().uuid(),
})

export const GetActionParamQueryStringSchema = z.object({
  mode: z.union([z.literal('edit'), z.literal('create')]),
  action: z.union([z.literal('sample'), z.literal('preventive')]),
})

export const GetActionParamResponseSchema = z.array(
  z.object({
    actionRefClientId: numberOrStringToNumberSchema,
    actionRefId: numberOrStringToNumberSchema,
    actionName: z.string(),
    conformIfCondition: z.boolean().nullable(),
    category: z.nativeEnum(ActionCategory),
    frequency: z.nativeEnum(Frequency),
    waterType: z.nativeEnum(WaterType).nullable(),
    actionType: z.nativeEnum(ActionType),
    selectedAction: z.boolean(),
    unit: z.string().nullable(),
    condition: conditionSchema.nullable(),
    conditionParamId: numberOrStringToNumberSchema.nullable().optional(),
    companyId: z.number().nullable(),
    equipmentRefId: z.number().nullable(),
    conditionRefClientType: z.nativeEnum(ConditionType).nullable(),
    conditionRefClientId: numberOrStringToNumberSchema.nullable(),
    timezone: z.string(),
    nextDeadline: z.string(),
    actionParamId: z.string().uuid().nullable().optional(),
  }),
)

export type GetActionParamPathParameter = z.infer<typeof GetActionParamPathParameterSchema>
export type GetActionParamResponse = z.input<typeof GetActionParamResponseSchema>
export type GetActionParamQueryString = z.input<typeof GetActionParamQueryStringSchema>
