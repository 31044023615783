import { UseCase } from '@core/referential/use-case.enum'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Outlet, notFound, useRouterState } from '@tanstack/react-router'
import { HTTPError } from 'ky'
import { Suspense } from 'react'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import Loader from '@/components/ui/loader'
import { useAuth } from '@/hooks/use-auth'
import { equipmentQuery } from '@/queries/equipment.query'

import { EditEquipmentBreadcrumb } from './-edit/edit-equipment-breadcrumb'

const SearchSchema = z.object({
  action: z.union([z.literal('preventive'), z.literal('sample')]).default('preventive'),
})

export const Route = createFileRoute('/_dashboard/site/$id/equipment/$equipmentId/_layout')({
  validateSearch: SearchSchema,
  component: EquipmentLayout,
  parseParams(params) {
    return z
      .object({
        equipmentId: z.string().uuid(),
      })
      .parse(params)
  },
  loaderDeps({ search }) {
    return {
      action: search.action,
    }
  },
  async loader({ params, deps, cause, context }) {
    // update current site id in the top-bar state (if you refresh the page for example)
    if ('id' in params && 'equipmentId' in params && cause !== 'preload') {
      try {
        return {
          equipment: await context.queryClient.ensureQueryData(equipmentQuery(params.id, params.equipmentId, { action: deps.action })),
        }
      } catch (error) {
        if (error instanceof HTTPError && !error.response.ok) {
          // if the equipment is not found or there were an error, redirect to the 404 page
          throw notFound()
        }

        throw error
      }
    }
  },
  pendingComponent: PendingComponent,
})

function EquipmentLayout() {
  const params = Route.useParams()
  const action = Route.useSearch({ select: (s) => s.action })
  const equipment = useSuspenseQuery(equipmentQuery(params.id, params.equipmentId, { action }))
  const pathname = useRouterState({
    select(state) {
      return state.location.pathname
    },
  })
  const user = useAuth((state) => state.user)

  const showActions = user?.currentUseCase === UseCase.LEGIONELLA || !!equipment.data.deviceId

  return (
    <div className="flex grow flex-col">
      <EditEquipmentBreadcrumb siteId={params.id} equipment={equipment.data} pathname={pathname} action={action} showActions={showActions} />

      <Suspense fallback={<Loader className="my-4 flex size-12" wrapperClassName="grow items-center justify-center h-full" />}>
        <Outlet />
      </Suspense>
    </div>
  )
}
