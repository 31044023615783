import { DeviceFamily } from '@core/referential/device-family.enum'
import { Frequency } from '@core/referential/frequency.enum'
import { z } from 'zod'

import { dateSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'

export const AllowedFrequencies = z.enum([Frequency.DAILY, Frequency.WEEKLY, Frequency.MONTHLY, Frequency.YEARLY] as const)
export const AllowedEnergy = z.enum([DeviceFamily.GAS, DeviceFamily.ELECTRICITY, DeviceFamily.FUEL, DeviceFamily.WATER, DeviceFamily.THERMAL] as const)

export const GetSiteDataEnergyPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetSiteDataEnergyQueryStringSchema = z.object({
  startDate: dateSchema,
  endDate: dateSchema,
  frequency: AllowedFrequencies,
  energy: AllowedEnergy,
  unit: z.union([z.literal('m3'), z.literal('kwh'), z.literal('mwh')]).default('kwh'),
})

export const GetSiteDataEnergyResponseSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  energy: AllowedEnergy,
  siteName: z.string().nullable(),
  data: z.array(z.any()),
})

type InferredGetSiteDataEnergyResponse = z.infer<typeof GetSiteDataEnergyResponseSchema>
export type GetSiteDataEnergyPathParameter = z.infer<typeof GetSiteDataEnergyPathParameterSchema>
export type GetSiteDataEnergyQueryString = z.infer<typeof GetSiteDataEnergyQueryStringSchema>
export type GetSiteDataEnergyResponse = Omit<InferredGetSiteDataEnergyResponse, 'data'> & {
  data: Array<[string, string | number]>
}
export type GetSiteDataFrequency = z.infer<typeof AllowedFrequencies>
