export enum DeviceType {
  LEGIONELLA = 'legionella',
  COLD_STORAGE = 'coldStorage',
  FEEDBACK_PANEL = 'feedbackPanel',
  CLEANING_VALIDATION = 'cleaningValidation',
  DOOR = 'door',
  WATER = 'water',
  MOTION = 'motion',
  CC = 'cc',
  WATER_METER = 'waterMeter',
  GAS_METER = 'gasMeter',
  ELECTRICITY_METER = 'electricityMeter',
  THERMAL_METER = 'thermalMeter',
  FUEL_METER = 'fuelMeter',
  TEMPERATURE = 'temperature',
  BATTERY = 'battery',
  PUMP = 'pump',
  COM = 'com',
  MANUAL = 'manual',
  HUMIDITY = 'humidity',
  CO2 = 'co2',
}
