import { ReportFrequency } from '@core/referential/frequency.enum'
import { z } from 'zod'

import { numberOrStringToNumberSchema } from '@shared/schema/utils'

export const GetReportConfigurationPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetReportConfigurationResponseSchema = z.array(
  z.object({
    id: z.string(),
    actions: z.object({
      addActionSample: z.boolean(),
      addActionTemperature: z.boolean(),
      addActionWaterTreatment: z.boolean(),
      addActionPurge: z.boolean(),
      addActionMaintenance: z.boolean(),
    }),
    addDevice: z.boolean(),
    addAlert: z.boolean(),
    frequency: z.nativeEnum(ReportFrequency),
    name: z.string().optional(),
    users: z
      .array(
        z.object({
          name: z.string(),
          id: z.string().uuid(),
        }),
      )
      .nullable(),
  }),
)

export type GetReportConfigurationPathParameter = z.infer<typeof GetReportConfigurationPathParameterSchema>
export type GetReportConfigurationResponse = z.infer<typeof GetReportConfigurationResponseSchema>
