export enum AlertParamType {
  TEMPERATURE_ALERT = 'temperatureAlert',
  FREQUENCE_COUNTER_ALERT = 'frequenceCounterAlert',
  CLICK_COUNTER_ALERT = 'clickCounterAlert',
  WATER_MOVEMENT_ALERT = 'waterMovementAlert',
  WATER_FLOW_ALERT = 'waterFlowAlert',
  HUMIDITY_ALERT = 'humidityAlert',
  BATTERY_ALERT = 'batteryAlert',
  DEFAULT_PUMP_ALERT = 'defaultPumpAlert',
  DEFAULT_COM_ALERT = 'defaultComAlert',
  DEFAULT_MANUAL_ALERT = 'defaultManualAlert',
  DEFAULT_CRITICAL_ALERT = 'defaultCriticalAlert',
  OTHER_DEFAULT_ALERT = 'otherDefaultAlert',
}
