import { AlertParamType } from '@core/referential/alert-param-type.enum'
import { DTEventType } from '@core/referential/dt-event-type.enum'
import { t } from 'i18next'

export function getEventTypeUnit(eventType?: DTEventType): string | undefined {
  switch (eventType) {
    case DTEventType.TEMPERATURE: {
      return '°C'
    }

    case DTEventType.WATER_FLOW: {
      return 'm/s'
    }

    case DTEventType.HUMIDITY: {
      return '%'
    }

    case DTEventType.CO2: {
      return 'ppm'
    }

    default: {
      return ''
    }
  }
}

export function getAlertParamTypeUnit(eventType?: AlertParamType): string {
  switch (eventType) {
    case AlertParamType.TEMPERATURE_ALERT: {
      return '°C'
    }

    case AlertParamType.WATER_FLOW_ALERT: {
      return 'm/s'
    }

    case AlertParamType.HUMIDITY_ALERT: {
      return '%'
    }

    default: {
      return ''
    }
  }
}

export function getDefaultEventType(eventTypes: Array<DTEventType>) {
  if (eventTypes.includes(DTEventType.TEMPERATURE)) return DTEventType.TEMPERATURE

  return eventTypes[0]
}

export function getChartTitle(eventType?: DTEventType): string {
  switch (eventType) {
    case DTEventType.TEMPERATURE: {
      return t('temperatureEvolution')
    }

    case DTEventType.WATER_FLOW: {
      return t('waterFlowEvolution')
    }

    case DTEventType.HUMIDITY: {
      return t('humidityEvolution')
    }

    default: {
      return ''
    }
  }
}

export const availableEventTypes = [DTEventType.TEMPERATURE, DTEventType.WATER_FLOW, DTEventType.HUMIDITY, DTEventType.WATER_MOVEMENT]
