import { WorkStatus } from '@core/referential/work-status.enum'
import { z } from 'zod'

import { numberOrStringToNumberSchema } from '@shared/schema/utils'

export const GetByIdWorkPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  workId: numberOrStringToNumberSchema,
})

export const GetByIdWorkSchemaResponse = z.object({
  id: numberOrStringToNumberSchema,
  siteId: numberOrStringToNumberSchema,
  name: z.string().trim(),
  location: z.string().trim().optional().nullable(),
  detail: z.string().optional().nullable(),
  company: z.string().optional().nullable(),
  responsible: z.string().optional().nullable(),
  planning: z.string().optional().nullable(),
  budget: z.string().optional().nullable(),
  status: z.nativeEnum(WorkStatus),
  dateDone: z.string().optional().nullable(),
  files: z.array(z.object({ id: z.string().uuid(), name: z.string(), date: z.string() })).optional(),
})

export type GetByIdWorkResponse = z.infer<typeof GetByIdWorkSchemaResponse>
export type GetByIdWorkPathParameter = z.infer<typeof GetByIdWorkPathParameterSchema>
