import dayjs from 'dayjs'
import { z } from 'zod'

import { timeRangeOptions } from './date.utils'

const defaultDateRange = {
  startDate: dayjs().subtract(3, 'hours').toISOString(),
  endDate: dayjs().toISOString(),
}
const defaultTimeRange = '3h'

export const SearchSchema = z.object({
  sites: z.array(z.string()).optional(),
  buildings: z.array(z.string()).optional(),
  equipments: z.array(z.string()).optional(),
  siteHistory: z.string().optional(),
  buildingHistory: z.string().optional(),
  floorsHistory: z.array(z.string()).optional(),
  spacesHistory: z.array(z.string()).optional(),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  timeRange: z.enum(timeRangeOptions).default(defaultTimeRange),
})

export type Search = z.infer<typeof SearchSchema>
