import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { isUseCase } from '@/lib/utils'

import { SearchSchema } from '../-utils/search'

const airHandlingUnitSearchSchema = z.object({
  chartHistory: z.union([z.literal('blowing'), z.literal('ambientTemp'), z.literal('co2')]).optional(),
  equipmentParentId: z.string().optional(),
})

export type AirHandlingUnitSearch = z.infer<typeof airHandlingUnitSearchSchema>

export const Route = createFileRoute('/_dashboard/energy-monitoring/air-handling-unit/')({
  validateSearch: SearchSchema.merge(airHandlingUnitSearchSchema),
  async beforeLoad() {
    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
