import { Frequency } from '@core/referential/frequency.enum'
import { validate as isUuid } from 'uuid'
import { z } from 'zod'

import { dateSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'

export const AllowedFrequencies = z.enum([Frequency.DAILY, Frequency.WEEKLY, Frequency.MONTHLY, Frequency.YEARLY] as const)

export const GetDeviceDataEnergyPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetDeviceDataEnergyQueryStringSchema = z.object({
  startDate: dateSchema,
  endDate: dateSchema,
  frequency: AllowedFrequencies,
  unit: z.union([z.literal('m3'), z.literal('kwh'), z.literal('mwh')]).default('kwh'),
  deviceIds: z
    .string()
    .regex(/^(?!.*,,)(?!.*,$)(?!^,).*$/)
    .transform((value) => value.split(','))
    .refine((value) => value?.every((v) => isUuid(v))),
})

export const GetDeviceDataEnergyResponseSchema = z.array(
  z.object({
    deviceId: z.string().uuid(),
    equipmentName: z.string().nullable(),
    data: z.array(z.any()),
  }),
)

type InferredGetDeviceDataEnergyResponse = z.infer<typeof GetDeviceDataEnergyResponseSchema>
export type GetDeviceDataEnergyPathParameter = z.infer<typeof GetDeviceDataEnergyPathParameterSchema>
export type GetDeviceDataEnergyQueryString = z.infer<typeof GetDeviceDataEnergyQueryStringSchema>
export type GetDeviceDataEnergyResponse = Array<
  Omit<InferredGetDeviceDataEnergyResponse[0], 'data'> & {
    data: Array<[string, string | number]>
  }
>
export type GetDeviceDataEnergyFrequency = z.infer<typeof AllowedFrequencies>
