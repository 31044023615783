export enum AlertType {
  TEMPERATURE = 'temperature',
  WATER_MOVEMENT = 'waterMovement',
  WATER_MOVEMENT_TEMPERATURE = 'waterMovementTemperature',
  PEOPLE_FLOW = 'peopleFlow',
  USAGE = 'usage',
  TOUCH = 'touch',
  WATER_FLOW = 'waterFlow',
  HUMIDITY = 'humidity',
  GAS = 'gas',
  ELECTRICITY = 'electricity',
  WATER = 'water',
  FUEL = 'fuel',
  THERMAL = 'thermal',
  DEFAULT = 'default',
  BATTERY = 'battery',
}
