export const timeRangeOptions = ['1h', '3h', '6h', '24h', '7d', 'custom'] as const

export type TimeRange = (typeof timeRangeOptions)[number]

export function getMode(timeRange: TimeRange): 'hour' | undefined {
  switch (timeRange) {
    case '1h':
    case '3h':
    case '6h':
    case '24h': {
      return 'hour'
    }

    case '7d':
    case 'custom': {
      return undefined
    }
  }
}
