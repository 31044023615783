import { createFileRoute, redirect } from '@tanstack/react-router'

import PendingComponent from '@/components/pending-component'
import { Permission } from '@/lib/permissions'
import { usersQuery } from '@/queries/users.query'

export const Route = createFileRoute('/_dashboard/users/')({
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.PATRIMONY_USERS)) {
      throw redirect({
        to: '/403',
      })
    }
  },
  loader({ context }) {
    return context.queryClient.ensureQueryData(usersQuery)
  },
  pendingComponent: PendingComponent,
})
