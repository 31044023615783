export enum Frequency {
  SPOT = 'spot',
  DAILY = 'daily',
  THREE_DAY = '3day',
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly',
  TWO_MONTH = '2month',
  THREE_MONTH = '3month',
  SIX_MONTH = '6month',
  YEARLY = 'yearly',
  TWO_YEAR = '2year',
  FIVE_YEAR = '5year',
}

export enum ReportFrequency {
  WEEKLY = Frequency.WEEKLY,
  MONTHLY = Frequency.MONTHLY,
  FORTNIGHTLY = Frequency.FORTNIGHTLY,
}
