/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import zodTranslationEn from 'zod-i18n-map/locales/en/zod.json'
import zodTranslationFr from 'zod-i18n-map/locales/fr/zod.json'

import { type TranslationKeys } from '@/i18n/translation-shape'

import fr from './fr'

void i18n
  // Detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr',
    lng: 'fr',
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: fr,
        zod: zodTranslationEn,
      },
      fr: {
        translation: fr,
        zod: zodTranslationFr,
      },
    },
  })

z.setErrorMap((issue, ctx) => {
  if (issue.code === 'invalid_string' && issue.message === undefined && issue.validation === 'regex') {
    const tsKey = `zod.${issue.path[0]}.regex` as TranslationKeys
    const message = i18n.t(tsKey)

    if (message !== 'undefined' && message !== 'null' && message !== '' && message !== tsKey) {
      return {
        message,
      }
    }
  }

  return zodI18nMap(issue, ctx)
})
