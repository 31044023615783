import { AlertParamType } from '@core/referential/alert-param-type.enum'
import { AlertSeverity } from '@core/referential/alert-severity.enum'
import { z } from 'zod'

import { conditionSchema, numberOrStringToNumberSchema } from '@shared/schema/utils'

export const GetAlertByIdPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
  alertParamGroupId: numberOrStringToNumberSchema,
})

export const GetAlertByIdResponseSchema = z.object({
  name: z.string(),
  sameAlertMinute: z.number(),
  type: z.nativeEnum(AlertParamType),
  severity: z.nativeEnum(AlertSeverity),
  deviceIds: z.array(z.string().uuid()),
  schedule: z
    .record(z.array(z.array(z.number())))
    .optional()
    .nullable(),
  disabled: z.boolean().optional().nullable(),
  condition: conditionSchema,
  users: z
    .array(
      z.object({
        userIds: z.array(z.object({ id: z.string().uuid(), name: z.string() })),
        sendMail: z.boolean().default(false),
        sendSms: z.boolean().default(false),
        sendCall: z.boolean().default(false),
        smsSchedule: z
          .record(z.array(z.array(z.number())))
          .optional()
          .nullable(),
        callSchedule: z
          .record(z.array(z.array(z.number())))
          .optional()
          .nullable(),
        mailSchedule: z
          .record(z.array(z.array(z.number())))
          .optional()
          .nullable(),
      }),
    )
    .optional(),
})

export type GetAlertByIdPathParameter = z.infer<typeof GetAlertByIdPathParameterSchema>
export type GetAlertByIdResponse = z.infer<typeof GetAlertByIdResponseSchema>
