import { GetByIdSiteSchemaResponse } from '@shared/schema/site.get-by-id.schema'
import { queryOptions } from '@tanstack/react-query'

import { type SiteId } from '@/@types/site'
import { apiFetch } from '@/lib/api-fetch'

export function siteQuery(siteId: SiteId | string) {
  return queryOptions({
    queryKey: ['site', siteId],
    async queryFn() {
      const rawResponse = await apiFetch.get(`site/${siteId}`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch site id ${siteId}`)
      }

      try {
        return await GetByIdSiteSchemaResponse.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse site id response', { cause: error })
      }
    },
  })
}
