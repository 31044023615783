import { DeviceFamily } from '@core/referential/device-family.enum'
import { DeviceSubType } from '@core/referential/device-sub-type.enum'
import { z } from 'zod'

export const GetDevicesResponseSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string().nullable(),
    equipmentName: z.string().optional(),
    equipmentId: z.string().uuid(),
    timezone: z.string().optional().nullable(),
    siteId: z.string(),
    siteName: z.string(),
    building: z.string().optional().nullable(),
    buildingId: z.string().optional().nullable(),
    metadata: z.object({
      deviceFamily: z.nativeEnum(DeviceFamily),
      subType: z.nativeEnum(DeviceSubType),
    }),
  }),
)

export type GetDevicesResponse = z.infer<typeof GetDevicesResponseSchema>
