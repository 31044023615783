import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { lazy, Suspense } from 'react'

import type { LoaderContext } from '@/@types/loader-context'
import { FullScreenLoader } from '@/components/ui/full-screen-loader'
import { ScrollArea } from '@/components/ui/scroll-area'
import NotFoundPage from '@/not-found-page'

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'development'
    ? lazy(() =>
        // Lazy load in development
        import('@tanstack/react-router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )
    : () => null

function RootLayout() {
  return (
    <ScrollArea>
      <Suspense>
        <Outlet />
        <ReactQueryDevtools buttonPosition="top-right" />
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </ScrollArea>
  )
}

export const Route = createRootRouteWithContext<LoaderContext>()({
  component: RootLayout,
  notFoundComponent: NotFoundPage,
  pendingComponent() {
    return <FullScreenLoader className="size-32" />
  },
})
