import { DeviceFamily } from '@core/referential/device-family.enum'
import { Frequency } from '@core/referential/frequency.enum'
import { UnitType } from '@core/referential/unit-type.enum'
import { UsageType } from '@core/referential/usage-type.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { AllowedFrequencies } from '@shared/schema/site.data-energy.schema'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { useAuth } from '@/hooks/use-auth'
import { parseDateRange } from '@/lib/date'
import { isUseCase } from '@/lib/utils'
import { companyDevicesQuery } from '@/queries/company.devices.query'

import { filterDeviceFamilies, filterDevices, getAvailableUnits } from '../-energy-usage/utils'

export const defaultDateRange = parseDateRange({
  startDate: dayjs().startOf('month').subtract(1, 'month').toDate(),
  endDate: dayjs().endOf('month').toDate(),
})

export const SearchSchema = z.object({
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  energy: z.nativeEnum(DeviceFamily).optional(),
  unit: z.nativeEnum(UnitType).default(UnitType.KWH),
  frequency: AllowedFrequencies.default(Frequency.MONTHLY),
  sites: z.array(z.string()).optional(),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/energy-usage/site-analysis/')({
  validateSearch: SearchSchema,
  async beforeLoad({ search, context: { queryClient } }) {
    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }

    const { setCurrentUsageType, user } = useAuth.getState()
    const usageType = user?.currentUsageType ?? UsageType.ENERGY

    if (!user?.currentUsageType) {
      setCurrentUsageType(usageType)
    }

    const companyDevices = await queryClient.ensureQueryData(companyDevicesQuery())
    const availableDevices = filterDevices({ devices: companyDevices, meterType: 'meter', usageType })
    const availableEnergy = filterDeviceFamilies({ devices: availableDevices, usageType })

    if ((!search.energy && availableEnergy.length > 0) || (search.energy && !availableEnergy.includes(search.energy))) {
      const energy = availableEnergy[0]
      const availableUnits = getAvailableUnits(energy)
      const unit = availableUnits.includes(search.unit) ? search.unit : availableUnits[0]

      throw redirect({
        to: '/energy-usage/site-analysis',
        search: { ...search, energy, unit },
        throw: false,
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})
