import { ActionCategory } from '@core/referential/action-category.enum'
import { ActionType } from '@core/referential/action-type.enum'
import { z } from 'zod'

import { numberOrStringToNumberSchema } from '@shared/schema/utils'

export const GetListByCategoryActionParamPathParameterSchema = z.object({
  siteId: numberOrStringToNumberSchema,
})

export const GetListByCategoryActionParamQueryStringSchema = z.object({
  category: z.nativeEnum(ActionCategory),
})

export const GetListByCategoryActionParamResponseSchema = z.array(
  z.object({
    equipmentId: z.string().uuid(),
    equipmentName: z.string(),
    category: z.nativeEnum(ActionCategory),
    siteId: numberOrStringToNumberSchema,
    companyId: numberOrStringToNumberSchema,
    actionId: z.string().uuid(),
    actionName: z.string(),
    firstResp: z.string().email().nullable(),
    firstResp2: z.string().email().nullable(),
    secondaryResp: z.array(z.string().email()).nullable(),
    buildingId: numberOrStringToNumberSchema.nullable(),
    building: z.string().nullable(),
    floorId: numberOrStringToNumberSchema.nullable(),
    floor: z.string().nullable(),
    spaceId: numberOrStringToNumberSchema.nullable(),
    space: z.string().nullable(),
    type: z.nativeEnum(ActionType),
  }),
)

export type GetListByCategoryActionParamPathParameter = z.infer<typeof GetListByCategoryActionParamPathParameterSchema>
export type GetListByCategoryActionParamResponse = z.input<typeof GetListByCategoryActionParamResponseSchema>
export type GetListByCategoryActionParamQueryString = z.input<typeof GetListByCategoryActionParamQueryStringSchema>
