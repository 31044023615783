export function createDefaultErrorComponent(message?: string) {
  return ({ error }: { error: unknown }) => (
    <div className="flex flex-col items-start gap-2">
      <h2 className="font-semibold leading-none text-primary">{message}.</h2>
      <pre className="rounded-md bg-slate-300 p-2">
        {}
        <code>{error instanceof Error ? `[${error.name}]: ${error.message}` : `unknown error: ${error}`}</code>
        {error instanceof Error && !!error.cause && error.cause instanceof Error && (
          <code>
            Caused by: [{error.cause.name}]: {error.cause.message}
          </code>
        )}
      </pre>
    </div>
  )
}
