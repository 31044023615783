import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'

export const SearchSchema = z.object({
  tab: z.union([z.literal('preventive'), z.literal('samplings')]).default('preventive'),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/equipment/')({
  validateSearch: SearchSchema,
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_EQUIPMENT)) {
      throw redirect({
        to: '/403',
      })
    }

    if (isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
})
